:root {
  --main-color: #775fe4;
  --secondary-color: #563fbd;
  --grey-text: #9b9b9b;
}

.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
  width: 100%;
}
.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid #9b9b9b;
  outline: 0;
  font-size: 1.3rem;
  color: #fff;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
  transition: color 0.2s;
}
.form__field::placeholder {
  color: transparent;
}
.form__field:placeholder-shown ~ .form__label {
  font-size: 1.3rem;
  cursor: text;
  top: 20px;
}
.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: var(--grey-text);
}

.form__helper__text {
  color: var(--grey-text);
  margin-top: 5px;
}

.form__field:focus {
  padding-bottom: 6px;
  border-width: 3px;
  border-image: linear-gradient(
    to right,
    var(--main-color),
    var(--secondary-color)
  );
  border-image-slice: 1;
}
.form__field:focus ~ .form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: var(--main-color);
}
/* reset input */
.form__field:required,
.form__field:invalid {
  box-shadow: none;
}

.form__field:disabled {
  color: var(--grey-text);
}
