.loading-box {
  position: relative;
  width: 100%;
  height: 8px;
  border-radius: 8px;
  overflow: hidden;
}

.loader {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 50px;
  background: linear-gradient(45deg, #b6b5ff, #ff9797);
  left: 0%;
}

.loader {
  left: -100%;
  animation: load 3s linear infinite;
}

.hidden {
  background: transparent;
}

@keyframes load {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
